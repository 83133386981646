import '../styles/main.scss'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Fancybox from "./utils/fancybox";
import Accordion from './components/accordion'
import BasicModal from './components/basicModal'
import Slider from './components/slider'
import Base from './components/base'

import AOS from 'aos'
import 'aos/dist/aos.css'

/* Hero animation */

// Wait for the page to load

document.addEventListener('DOMContentLoaded', () => {

    const homeHero = document.querySelector('#hero');
    const animatedSvg = document.getElementById('animated-svg');
    if(homeHero) {
        const hero = document.getElementById('hero');
        const fadeElement = animatedSvg.querySelector('.fade-in-element');
        const starElement = animatedSvg.querySelector('.star');
        const main = document.getElementById('page');

        animatedSvg.addEventListener('animationend', handleAnimationEnd);

        function handleAnimationEnd() {
            setTimeout(delayedFadeIn, 500);
        }

        function delayedFadeIn() {
            fadeElement.style.opacity = 1;
            starElement.style.opacity = 0;
            setTimeout(addAnimationFinishedClass, 500);

        }

        function addAnimationFinishedClass() {
            hero.classList.add('animation-finished');
            setTimeout(addAnimationEndClass, 500);

        }

        function addAnimationEndClass() {
            hero.classList.add('animation-end');
            main.style.display = 'block';
            setTimeout(addAnimationFinalClass, 0);
        }

        function addAnimationFinalClass() {
            hero.classList.add('animation-final');
            setTimeout(initializeAOS, 1000);
        }
        function initializeAOS() {
            AOS.init();
            if (animatedSvg) {
                animatedSvg.remove(); // Remove the hero element from the DOM
            }
        }

    } else {
        AOS.init();
    }


    /*
   Siseviimistlus tabid
  */

    // TABS

    const tabButtons = document.querySelectorAll('.tab');
    const tabContents = document.querySelectorAll('.tab-pane');

    tabButtons.forEach(button => {
        button.addEventListener('click', () => {
            const selectedTabId = button.dataset.tabId;

            // Remove active class from all tabs
            tabButtons.forEach(b => b.classList.remove('active'));

            // Add active class to clicked tab
            button.classList.add('active');

            // Hide all content divs
            tabContents.forEach(content => content.classList.remove('active'));

            // Show the content div for the selected tab
            document.getElementById(selectedTabId).classList.add('active');
        });
    });





});

document.addEventListener('DOMContentLoaded', () => {
    Fancybox({
        transitionEffect: "slide" // Sliding animation between images
    });


    /*
    Base
     */

    const base = document.querySelector('.base');
    if (base) {
        createRoot(base).render(<Base items="Just a demo content for base React component. In case I forget how React works :D"/>);
    }

    /*
        Slider
     */
    const slider = document.querySelector('.slider');
    if (slider) {
        createRoot(slider).render(<Slider items={sliderData}/>);
    }

    /*
    * Accordion
    * */
    const accordion = document.querySelector('.accordion');
    if (accordion) {
        createRoot(accordion).render(<Accordion items={repeaterData}/>);
    }

    const tabs = document.querySelector('.repeater-tabs');
    if (tabs) {
        createRoot(tabs).render(<VerticalTabs items={repeaterData}/>);
    }

    /*
    Modal
     */

    const modal = document.querySelector('.modal');
    if (modal) {
        createRoot(modal).render(<BasicModal items={modalData}/>);
    }

    /*
    Scroll to top button
     */

    /*
    Change header on scroll
     */
    const header = document.getElementById("masthead");
    window.addEventListener("scroll", function () {
        const scroll = window.scrollY;
        if (scroll >= 50) {
            header.classList.add("scrolled");
        } else {
            header.classList.remove("scrolled");
        }
    });

    /*
    Toggle mobile navigation
     */

    const burgerIcon = document.querySelector(".primary-burger");
    const body = document.querySelector("body");

    burgerIcon.addEventListener("click", function () {
        body.classList.toggle("nav-is-open");
    });

// Function to update logo class based on the position of .logo relative to .dark-bg

    /*
    function updateLogoClass() {
        const logo = document.querySelector('.nav-container');
        const darkBgElements = document.querySelectorAll('.dark-bg');
        let logoOnDarkBg = false;

        darkBgElements.forEach((darkBg) => {
            const logoRect = logo.getBoundingClientRect();
            const darkBgRect = darkBg.getBoundingClientRect();

            // Check if .logo is fully contained within or overlaps with any .dark-bg
            if (
              logoRect.top >= darkBgRect.top &&  // Logo top below or equal to .dark-bg top
              logoRect.bottom <= darkBgRect.bottom  // Logo bottom above or equal to .dark-bg bottom
            ) {
                logoOnDarkBg = true;
            }
        });

        if (logoOnDarkBg) {
            logo.classList.add('dark-logo');
        } else {
            logo.classList.remove('dark-logo');
        }
    }

// Add an event listener for scrolling
    window.addEventListener('scroll', updateLogoClass);

// Initial call to set the logo class on page load
    updateLogoClass();
*/


    /* smooth scroll */
    // Add smooth scrolling behavior to anchor links
    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    anchorLinks.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();

            const targetId = link.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                scrollToElement(targetElement, 2000); // Adjust the duration (in milliseconds) here
            }
        });
    });

    function scrollToElement(element, duration) {
        const elementTop = element.getBoundingClientRect().top + window.scrollY;
        const startingY = window.scrollY;
        const diff = elementTop - startingY;
        let start;

        window.requestAnimationFrame(function step(timestamp) {
            if (!start) start = timestamp;
            const time = timestamp - start;
            const percent = Math.min(time / duration, 1);

            window.scrollTo(0, startingY + diff * percent);

            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        });
    }

    /* Sorting */

    const sortColumns = document.querySelectorAll('.sort-column');
    let currentSort = {};

    sortColumns.forEach(column => {
        column.addEventListener('click', () => {
            const attributeName = column.getAttribute('data-sort');
            let sortOrder = 'asc';

            if (currentSort.attributeName === attributeName && currentSort.order === 'asc') {
                sortOrder = 'desc';
            }

            currentSort = { attributeName, order: sortOrder };

            sortColumns.forEach(column => {
                column.classList.remove('asc', 'desc');
            });

            column.classList.add(sortOrder);

            const items = Array.from(document.querySelectorAll('#apartment-list > li'));

            items.sort((a, b) => {
                const aValue = a.getAttribute('data-' + attributeName);
                const bValue = b.getAttribute('data-' + attributeName);

                if (sortOrder === 'asc') {
                    return aValue.localeCompare(bValue, undefined, { numeric: true });
                } else {
                    return bValue.localeCompare(aValue, undefined, { numeric: true });
                }
            });

            const apartmentList = document.querySelector('#apartment-list');
            items.forEach(item => apartmentList.appendChild(item));
        });
    });

    // Filter accordion
    const openFilterElement = document.querySelector('#openFilter');
    const filterAccordionElement = document.querySelector('.filterAccordion');

    if (openFilterElement && filterAccordionElement) {

        function toggleAccordion() {
            filterAccordionElement.style.display = (filterAccordionElement.style.display === 'block') ? 'none' : 'block';
            openFilterElement.classList.toggle('open');
        }

        openFilterElement.addEventListener('click', toggleAccordion);
    }


    /*
Arch
*/
    const archElements = document.querySelectorAll('.pageIntro');
    if (archElements.length > 0) {
        function isElementInViewport(el, threshold = 0) {
            const rect = el.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            return (
                rect.bottom >= 0 &&
                rect.top <= viewportHeight - threshold
            );
        }

        function handleScroll() {
            archElements.forEach(arch => {
                if (isElementInViewport(arch)) {
                    setTimeout(() => {
                        arch.classList.add('arched'); // add class "arched" to arch
                    }, 500); // Delay of 500 milliseconds
                }
            });
        }

        window.addEventListener('scroll', handleScroll);
    }

    const homeUrl = myLocalizedData.homeUrl;
    //console.log(homeUrl);

    const paths = document.querySelectorAll('.floor_map path, .status-layer path');

    if (paths) {
        paths.forEach(function(path) {
            let aptNumber;

            const id = path.getAttribute('id');
            const dataPathApt = path.getAttribute('data-pathapt');

            if (id) {
                aptNumber = id.split('-')[1]; // Extract apt number from id
            } else if (dataPathApt) {
                aptNumber = dataPathApt; // Use data-pathapt value
            }


            if (aptNumber) {
                // Add 'current-path' class if the path matches the current URL
                if (window.location.href.includes('korter-' + aptNumber)) {
                    path.classList.add('current-path');
                }

                path.addEventListener('click', function() {
                    const url = homeUrl + "/korter/korter-" + aptNumber;
                    window.location.href = url;
                });

                // Make the path visually indicate that it's clickable
                path.classList.add('clickable');
            }
        });
    }

    /* end */
});